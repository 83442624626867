.root {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
}

.list {
    min-height: 200px;
    height: 100%;
    padding: 0 26px 0 28px;
}

.usersList {
    min-height: 200px;
    height: 100%;
    padding: 29px 29px 22px 24px;
}

.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.emptyDescription {
    color: #a3a8bb;
    font-size: 16px;
    font-weight: 300;
    margin: 0 0 5px;
}

.usersItem {
    display: flex;
    margin-bottom: 19px;
    font-size: 12px;
    justify-content: space-between;
}

.members {
    font-size: 11px;
    color: #a3a8bb;
}
