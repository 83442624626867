.root {
    background-color: #fff;
    min-width: 295px;
    padding: 13px 10px 17px 15px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    max-height: 100vh;
    position: sticky;
    top: 0;

    display: flex;
    flex-direction: column;
}

.chatOpenAction {
    right: 1px;
    top: 70px;
    position: absolute;
    min-width: 35px;
    padding-left: 0;
    height: 200px;
    display: flex;
    align-items: center;
    padding-right: 5px;
    justify-content: center;

    &:hover {
        opacity: 1;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
    }
}

.chatOpenActionText {
    width: 1px;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
}

.chatCloseAction {
    position: relative;
    min-width: 160px;
    width: 160px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 35px;
}

.chatIcon {
    left: 13px;
    bottom: 10px;
    position: absolute;
}

.messages {
    display: flex;
    height: calc(100vh - 280px);
    overflow: auto;
}

.dateSeparator {
    padding-top: 7px;
    border-top: 1px solid #f0f1f6;
    text-align: center;
    font-size: 11px;
    line-height: 1;
    color: #a3a8bb;
}

.actionLoadMoreMessages {
    color: #5f6683;
    font-size: 11px;
    margin-left: auto;
    margin-right: auto;
    min-width: 100px;
    padding: 5px 0;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid #f0f1f6;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
}

.countUnreadMessages {
    position: absolute;
    border-radius: 5px;
    top: -3px;
    width: 15px;
    padding: 3px;
    display: block;
    left: 0;
    background-color: #ff6578;
}
