.modal {
    max-width: 421px;
    width: 100%;
}

.close {
    right: 56px;
    top: 36px;
}

.header {
    padding-left: 58px;
    padding-right: 58px;
}

.headerTitle {
    color: #404040;
}

.container {
    padding: 70px 57px 56px;
}

.message {
    padding-bottom: 71px;
    padding-left: 20px;
    font-size: 12px;
    color: #404040;
}

.actions {
    display: flex;
    justify-content: space-between;
}

.action {
    min-width: 140px;
    padding-top: 11px;
    padding-bottom: 11px;

    font-weight: 500;
}

.actionCancel {
    color: #5f6683;
}

.actionDone {
    display: flex;
    justify-content: flex-end;
}
