.wrapper {
}

.root {
    display: flex;
    flex-direction: column;
}

.actionGoProjects {
    min-width: 100px;
    padding: 5px;
    margin-top: 10px;
}

.toast {
    width: 320px;
    margin: 0 auto;
}
