.accordion {
    border: none;
    width: 100%;
    padding-top: 14px;
}

.accordionItem {
    border-radius: 3px;
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
}

.accordionIcon {
    transform: rotate(90deg);
    transition: transform 500ms ease;
    margin-right: 15px;
}

.accordionItemActive {
    composes: accordionItem;
    .accordionIcon {
        transition: transform 500ms ease;
        transform: rotate(-90deg);
    }
}

.accordionButton {
    display: flex;
    align-items: center;
    padding: 0 31px;
    height: 60px;
    font-size: 16px;
    font-weight: 600;
    color: #4c4e5a;
    line-height: 1;
    border-bottom: 1px solid #f0f1f6;

    &:hover {
        background-color: #fff;
        cursor: pointer;
    }
}

.list {
    height: 50px;
    display: flex;
    align-items: center;
    margin-left: auto;
    border-left: 1px solid #f0f1f6;
    padding: 16px;
}

.listWithoutBorder {
    border-left: none;
}

.count {
    margin-right: 22px;
}

.accounts {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    font-size: 12px;
    font-weight: 500;
    color: #4c4e5a;
}

.actionMenu {
    margin-left: auto;
}

.accountUser {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 900;
}

.accordionPanel {
    padding: 20px 37px 45px 43px;
}

.inviteAction {
    min-width: 80px;
    padding: 9px 22px;
    margin-left: auto;
    font-size: 11px;
    font-weight: 500;
    color: #fffcfc;
}

.empty {
    display: flex;
    padding-top: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.emptyDescription {
    color: #a3a8bb;
    font-size: 20px;
    font-weight: 300;
    margin: 0;
}
