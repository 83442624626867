.root {
    height: 600px;

    background: url(../../../../assets/images/top.jpg) center/cover no-repeat;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 290px;
        top: 0;
        right: 0;
        opacity: 0.5;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

.topRow {
    padding-top: 35px;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
}

.banner {
    margin-top: 165px;
    position: relative;
    z-index: 2;
    color: #ffffff;
}

.navItem {
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    color: #fffcfc;
    margin-right: 55px;
    text-decoration: none;

    &:last-child {
        margin-right: 0;
    }

    &:hover {
        opacity: 0.8;
        text-decoration: underline;
    }
}

.navItemBorder {
    //border: solid 2px #9dce6c;
    border: solid 2px #6dc713;
    border-radius: 3px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    padding: 9px 32px;
}

.title {
    display: flex;
    flex-direction: column;
}

.titleColor {
    //color: #9dce6c;
    color: #6dc713;
}

.titleSecondary {
    font-size: 42px;
}

.bannerParagraph {
    max-width: 580px;
    font-size: 16px;
    font-weight: 500;
}
