.accordion {
    border: none;
    width: 100%;
}

.accordionItem {
    border-radius: 3px;
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
}

.accordionIcon {
    transform: rotate(90deg);
    transition: transform 500ms ease;
    margin-right: 15px;
}

.accordionItemActive {
    composes: accordionItem;
    .accordionIcon {
        transition: transform 500ms ease;
        transform: rotate(-90deg);
    }
}

.accordionButton {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    font-size: 14px;
    font-weight: 600;
    color: #4c4e5a;
    line-height: 1;
    border-bottom: 1px solid #f0f1f6;

    &:hover {
        background-color: #fff;
        cursor: pointer;
    }
}

.list {
    height: 50px;
    display: flex;
    align-items: center;
    margin-left: auto;
    border-left: 1px solid #f0f1f6;
    padding: 16px;
    min-width: 277px;
}

.listWithoutBorder {
    border-left: none;
}

.count {
    margin-right: 22px;
    color: #5e6a66;
    font-size: 13px;
    font-weight: 500;
}

.accountUser {
    margin-right: 2px;
}

.accordionPanel {
    background-color: #d2e1df;
    padding: 10px;
    padding-bottom: 1px;
}

.inviteAction {
    min-width: 80px;
    padding: 9px 22px;
    margin-left: 21px;
    font-size: 12px;
    font-weight: 500;
    color: #fffcfc;
}
