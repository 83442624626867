.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.field {
    max-width: 290px;
}

.textarea {
    padding: 14px 18px;
    height: 135px;
}

.submit {
    margin-left: auto;
    margin-top: 25px;
}
