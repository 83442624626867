.filter {
  margin-right: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
}

.text {
  margin-left: 10px;
  color: #5f6683;
  font-size: 12px;
  font-weight: 500;
}