.root {
    display: flex;
    max-height: 165px;
}

.actions {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    margin-top: 0;
    color: #4c4e5a;
    font-size: 12px;
    font-weight: normal;
}

.actionImage {
    border-radius: 5px;
    min-width: 144px;
    margin-bottom: 13px;
    height: 30px;
    font-size: 11px;
    font-weight: 500;
    color: #fffcfc;
}

.actionImageRemove {
    color: #5f6683;
    margin-bottom: 0;
}

.avatar {
    object-fit: contain;
}
