.root {
    display: flex;
    flex-direction: column;

    &:hover {
        cursor: pointer;
    }
}

.checkboxWrapper {
    display: flex;
    align-items: center;
}

.input {
    display: none;
}

.checkbox {
    width: 18px;
    height: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #f0f1f6;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
}

.checkboxIcon {
    width: 14px;
    height: 14px;
    fill: #9dce6c;
    opacity: 0;
}

.input:checked + .checkbox .checkboxIcon {
    opacity: 1;
}

.label {
    font-size: 10px;
    color: #4c4e5a;
    margin-left: 12px;
}

.errorMessage {
    color: #ff364e;
    top: 100%;
    margin-top: 6px;
    font-size: 8px;
}
