.root {
    width: 100%;
}

.questionsList {
    margin-top: 20px;
}

.empty {
    display: flex;
    margin: 0 auto;
    padding-top: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.emptyDescription {
    color: #a3a8bb;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
}
