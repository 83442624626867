.root {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #e7e7e9;
    min-height: 77px;
}

.wrapper {
    display: flex;
    align-items: center;
}

.title {
    font-size: 20px;
    font-weight: 500;
    color: #404040;
    margin: 0;
}
