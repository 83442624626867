.form {
    width: 100%;
    max-width: 360px;
}

.filed {
    margin-bottom: 20px;
}

.actions {
    display: flex;
    justify-content: space-between;
    margin-top: 105px;
}

.actionForgotPassword {
    color: #9dce6c;
    box-shadow: none;
}

.link {
    text-decoration: none;
}
