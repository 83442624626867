.root {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 500;
    color: #5f6683;
    border: none;
    background-color: transparent;
    overflow: hidden;
    &:hover:not(.icon) {
        cursor: pointer;
        color: darken(#5f6683, 50);
    }
}

.icon {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    border: solid 1px #f0f1f6;
    background-color: #ffffff;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}

.root:hover .icon {
    transform: rotate(360deg);
    transition: transform 1s ease;
}
