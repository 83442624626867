.root {
    position: relative;
}

.actionSubmit {
    position: absolute;
    background-color: transparent;
    border: none;
    width: 40px;
    height: 40px;
    padding: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
}

.actionCross {
    @extend .actionSubmit;

    left: unset;
    right: 0;
    top: 0;
}

.field {
    width: 260px;
}

.input {
    padding-left: 45px;
    padding-right: 45px;
}
