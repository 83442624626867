.overlay {
    top: 0;
    bottom:0;
    position:fixed;
    overflow-y:scroll;
    overflow-x:hidden;

    background-color: rgba(88, 123, 119, 0.8);
    width: 100vw;
    z-index: 10;

    padding-top: 50px;
    padding-bottom: 50px;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
}

.modal {
    position: relative;
    border-radius: 5px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    background-color: #f7f8fa;
    margin-left: auto;
    margin-right: auto;
}

.actionClose {
    border: none;
    background-color: transparent;
    padding: 0;
    position: absolute;
    right: 20px;
    top: 20px;

    &:hover {
        cursor: pointer;
        transition: transform 500ms ease;
        transform: rotate(360deg);
    }
}
