.root {
    font-size: 18px;
    font-weight: normal;
    color: #4c4e5a;
    margin: 0;
}

.name {
  font-weight: 600;
}
