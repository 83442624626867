.root {
    flex: 1;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    height: 126px;
    background-color: #ffffff;
    display: flex;
    position: relative;

    &:before {
        border-top: 1px solid #f0f1f6;
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
    }
}

.avatar {
    z-index: 2;
    margin-right: 20px;
}

.data {
    padding-top: 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.actions {
    display: flex;
    align-items: center;
}

.btn {
    width: 120px;
    min-width: unset;
    height: 30px;
    color: #5f6683;
    font-size: 11px;
    font-weight: 500;
    border: solid 1px #f0f1f6;
    margin-right: 12px;
}

.btnGoToProject {
    color: #fffcfc;
}

.actionProject {
    text-decoration: none;
}
