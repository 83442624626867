.root {
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
  height: 60px;
  display: flex;
  width: 98%;
  margin-bottom: 20px;
}

.accountUser {
  margin-bottom: 10px;
}

.col {
  display: flex;
  align-items: center;
  padding: 25px;
}

.colName {
  width: 34%;
  align-items: stretch;
  border-right: 1px solid #f0f1f6;
  font-size: 14px;
  font-weight: bold;
  color: #4c4e5a;
  padding: 10px;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
}

.colPhone {
  width: 20%;
  border-right: 1px solid #f0f1f6;
  color: #a3a8bb;
  font-size: 12px;
  font-weight: 600;
}

.colEmail {
  width: 48%;
  font-family: OpenSans;
  font-size: 12px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  //border-right: 1px solid #f0f1f6;
  color: #a3a8bb;
  word-break: break-all;
}

.colActions {
  width: 15%;
  font-size: 14px;
  font-weight: 600;
  padding-right: 15px;
  justify-content: flex-end;
  color: #a3a8bb;
}

.actionMenu {
  margin-left: auto;
}

.name {
  padding-top: 5px;
  margin-bottom: 3px;
  overflow: hidden;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 5px;
}

.userType {
  margin-top: auto;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 15px;
  height: 15px;
}
.button {
  min-width: 80px;
  height: 33px;
}
