.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.label {
    font-size: 12px;
    margin-bottom: 5px;
    color: #4c4e5a;
    position: relative;
}

.required {
    padding-left: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #ff364e;
    position: absolute;
}

.input {
    width: 100%;
    border-radius: 3px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
    height: 40px;
    font-size: 14px;
    border: none;

    color: #404040;
    font-weight: 500;

    &:focus {
        outline: 1px solid #000000;
    }

    &:disabled {
        box-shadow: none;
    }

    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-weight: 300;
        color: #8f94ab;
    }
    &::-moz-placeholder {
        /* Firefox 19+ */
        font-weight: 300;
        color: #8f94ab;
    }
    &:-ms-input-placeholder {
        /* IE 10+ */
        font-weight: 300;
        color: #8f94ab;
    }
    &:-moz-placeholder {
        /* Firefox 18- */
        opacity: 1;
        font-weight: 300;
        color: #8f94ab;
    }
}

.inputPasswordToggle {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0 15px;
    height: 40px;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: #a3a8bb;

    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
}

.inputPasswordToggleError {
    fill: #ff6578;
}

.inputError {
    box-shadow: 0 0 2px #ff6578;

    &:focus {
        box-shadow: 0 0 2px #ff6578;
    }
}

.textarea {
    resize: none;
    font-size: 14px;
    color: #404040;
    font-weight: 500;
}

.errorMessage {
    color: #ff364e;
    top: 100%;
    margin-top: 6px;
    font-size: 8px;
    position: absolute;
}

.searchIcon {
    position: absolute;
    top: 13px;
    left: 10px;
}
