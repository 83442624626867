.container {
    max-width: 1185px;
    width: 100%;
    padding: 0 10px;
    margin: 0 auto;
}

.titleMain {
    line-height: 1;
    font-size: 58px;
    font-weight: 600;
}
