.root {
    max-width: 180px;
    display: flex;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
    border-radius: 3px;
    align-items: center;
    overflow: hidden;
    position: fixed;
    left: 49px;
    bottom: 60px;
}

.name {
    margin-left: 15px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.tooltip {
    margin-left: auto;
}

.actionActiveTooltip {
    height: 40px;
    display: flex;
    align-items: center;
    padding-right: 15px;
    padding-left: 15px;
}

.avatar {
    min-width: 40px;
    font-weight: 900;
    object-fit: contain;
}
