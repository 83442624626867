.root {
    padding-left: 106px;
    min-height: 92px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid#f0f1f6;
}

.title {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
}

.projectTitle {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.35px;
    color: #8f94ab;
    margin-right: 130px;
}
