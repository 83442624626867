.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.text {
  font-size: 15px;
  font-weight: 700;
}