.root {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 95px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #fff;
    border-bottom: 1px solid #f0f1f6;
    border-radius: 3px 3px 0 0;
}

.headerActions {
    display: flex;
}

.title {
    color: #4c4e5a;
    font-size: 16px;
    font-weight: 600;
}

.editTeaser {
    margin-right: 10px;
}

.presentationWrapper {
    height: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
}

.descriptionNotTeaser {
    font-size: 16px;
    font-weight: 300;
    color: #a3a8bb;
    margin-top: 0;
    margin-bottom: 13px;
}

.presentationFrame {
    width: 100%;
    height: 550px;
}

.action {
    min-width: 90px;
    padding: 10px;
    font-size: 11px;
}

.actionSmall {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    border-radius: 5px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    background-color: #9dce6c;
    text-decoration: none;
    font-size: 11px;
    font-weight: 500;
    color: #fffcfc;

    &:hover {
        opacity: 0.7;
    }
}

.form {
    width: 400px;
    background-color: #f7f8fa;
    padding: 40px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.09);
}

.formActions {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.actionSaveUrl {
    margin-left: 10px;
}

.field {
    margin-bottom: 15px;
}

.tooltip {
    margin-right: 6em;
}