.root {
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    height: 60px;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}

.projectTitleLink {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    padding-right: 10px;

    &:hover {
        text-decoration: underline;
        transform: scale(1.05);
        transition: transform 200ms ease;
    }
}

.col {
    display: flex;
    align-items: center;
    padding: 25px;
}

.colName {
    width: 25%;
    border-right: 1px solid #f0f1f6;
    font-size: 14px;
    font-weight: bold;
    color: #4c4e5a;
}

.colOwner {
    width: 15%;
    border-right: 1px solid #f0f1f6;
    font-size: 12px;
    font-weight: bold;
    color: #4c4e5a;
}

.colState {
    width: 15%;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    border-right: 1px solid #f0f1f6;
}

.colAccessible {
    width: 25%;
    justify-content: center;
    border-right: 1px solid #f0f1f6;
}

.colActions {
    display: flex;
    align-items: center;
    width: 20%;
    padding-right: 15px;
}

.actionBtns {
    display: flex;
    flex-grow: 1;
    margin-right: 15px;
}

.actionBtn {
    flex-grow: 1;
    min-width: auto;
    font-size: 10px;
    padding-top: 9px;
    padding-bottom: 9px;

    &:first-child {
        margin-right: 10px;
    }

    &:disabled {
        cursor: not-allowed;
        // Root btn background-color is set as a style property field
        background-color: white !important;
        border: solid 1px #f0f1f6;
        color: black;
    }
}

.actionMenu {
    margin-left: auto;

    & > a {
        display: flex;
    }
}

.tag {
    padding: 8px 8px 9px;
}
