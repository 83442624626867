.modal {
  max-width: 500px;
  width: 100%;
}

.close {
  right: 56px;
  top: 36px;
}

.header {
  padding-left: 58px;
  padding-right: 58px;
}

.headerTitle {
  color: #404040;
}

.container {
  padding: 40px 57px 56px;
}

.message {
  padding-bottom: 40px;
  font-size: 15px;
  color: #404040;
}

.form {
  width: 400px;
  background-color: #f7f8fa;
  padding: 40px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.09);
}

.formActions {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.actionSaveUrl {
  margin-left: 10px;
}

.field {
  margin-bottom: 15px;
}
