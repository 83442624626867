.root {
    max-width: 48%;
    margin-right: 30px;
    width: 100%;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);

    display: flex;
    border-radius: 5px;
    overflow: hidden;
    &:nth-child(2n) {
        margin-right: 0;
    }

    margin-bottom: 27px;
}

.preview {
    min-width: 165px;
    min-height: 165px;
    object-fit: contain;
}

.content {
    width: 100%;
    background-color: #f5f4f4;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding-bottom: 15px;
}

.title {
    padding-top: 22px;
    padding-bottom: 18px;
    margin: 0;
    padding-left: 15px;
    background-color: #fff;
    border-bottom: 1px solid #f0f1f6;
    font-size: 16px;
    font-weight: 600;
    color: #4c4e5a;
}

.businessType {
    margin: 0;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.25px;
    color: #4b736e;
    padding: 12px 0 12px 14px;
    text-transform: uppercase;
}

.projectDescription {
    margin: 0;
    font-size: 11px;
    color: #a3a8bb;
    padding: 0 16px 8px 16px;
}

.actions {
    margin-top: auto;
    display: flex;
    padding-left: 15px;
}

.action {
    margin-top: 5px;
    font-size: 11px;
    min-width: 120px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 11px;
    border-radius: 5px;
    font-weight: bold;
    color: #fffcfc;
}

.openBtn {
    color: #5f6683;
    font-weight: 500;
    background-color: #fff;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    &:hover {
        opacity: 0.7;
    }
}
