.root {
    min-height: 100vh;
    //background: #587b77 url(../../../../assets/images/login.jpg) left no-repeat;
    background: #6f934b url(../../../../assets/images/login.jpg) left no-repeat;
    background-size: 50% 100%;
    color: #ffffff;
    display: flex;
}

.container {
    display: flex;
    justify-content: space-between;
}

.leftCol {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.wrapperTitle {
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    top: -140px;
}

.titleMain {
    margin: 0;
    font-size: 36px;
}

.paragraph {
    margin: 15px 0 0;
    font-size: 15px;
    font-weight: 600;
}

.rightCol {
    width: 50%;
    min-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f6f8;
}
