.root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff url(../../../../assets/images/bottom.jpg) bottom no-repeat;
    background-size: 101% 520px;
    padding-bottom: 100px;

}

.wrapper {
    border-radius: 5px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    background-color: #f7f8fa;
    max-width: 810px;
    width: 100%;
    padding: 55px 95px 50px;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 112px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #f0f1f6;
    }
}

.title {
    background-color: #f7f8fa;
    color: #4c4e5a;
    font-size: 22px;
    font-weight: 600;
    line-height: 1;
    margin: 0 0 40px;
}
