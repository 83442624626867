label.root {
    color: #000000;

    .input {
        width: 100%;
        border-radius: 3px;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
        background-color: #ffffff;
        height: 40px;
        font-size: 14px;
        border:none;
        font-family: Montserrat, Arial, sans-serif;
        color: #404040;
        font-weight: 500;
    }

    .dropDownButton {
        background-color: #fff;
        border: none;

        &:global(.selected-flag) {
            background-color: #fff;
        }

        &:hover {
            outline: 1px solid #000000;
        }

    }
}
