.cards {
    margin-left: 15px;
    width: 375px;
    display: flex;
    flex-wrap: wrap;
}

.card {
    min-width: 180px;
    min-height: 161px;
    margin-right: 15px;
    margin-bottom: 15px;
    font-size: 48px;
}

.card:nth-child(2n) {
    margin-right: 0;
}

.card:nth-child(n + 3) {
    margin-bottom: 0;
}
