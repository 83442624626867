.root {
    min-height: 100vh;
    //background: #587b77 url(../../../../assets/images/login.jpg) left no-repeat;
    background: #6f934b url(../../../../assets/images/login.jpg) left no-repeat;
    background-size: cover;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 80px;
    margin: 0;
}

.link {
    margin-top: 20px;
    color: #ffffff;
    border-radius: 3px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    background-color: #9dce6c;
    padding: 10px 15px;
    text-decoration: none;
    font-weight: 500;

    &:hover {
        opacity: 0.9;
        cursor: pointer;
    }
}
