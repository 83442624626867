.empty {
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.emptyDescription {
    color: #a3a8bb;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
}

.dateBlock {
    font-size: 11px;
    letter-spacing: 0.28px;
    color: #a3a8bb;
    margin: 15px 0 14px 0;
}
