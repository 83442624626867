.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 72px;
    height: 72px;
    border-radius: 3px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
    font-size: 24px;
    color: #a3a8bb;
    padding-top: 17px;
    padding-bottom: 15px;
    line-height: 1;
}

