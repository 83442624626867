.container {
    padding: 50px 93px 101px 78px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.35px;
    color: #404040;
}

.modal {
    width: 688px;
}

.header {
    padding-left: 46px;
}

.headerTitle {
    font-size: 24px;
    font-weight: 600;
    color: #4c4e5a;
}

.wrapperContent {
    width: 100% !important;
    height: 220px !important;
}

.content {
    padding-right: 10px;
}

.continueBtn {
    margin-top: 5px;
}
