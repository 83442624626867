.root {
    min-width: 165px;
    padding: 15px 15px;
    border: none;
    background-color: transparent;
    font-size: 15px;
    line-height: 1;
    color: #ffffff;
    border-radius: 3px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    &:disabled {
        opacity: 0.6;
    }
}
