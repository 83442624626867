.root {
    min-height: 48px;
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
    display: flex;
    line-height: 1;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #f0f1f6;
}

.title {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    color: #4c4e5a;
}
