.buttonCards {
    display: flex;
    flex-wrap: wrap;
    margin-right: -2.5%;
    margin-left: -2.5%;
}

.wrapperChart {
    width: 100%;
    height: 500px;
    margin-bottom: 50px;
}

.wrapperTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.downloadFileButton {
    margin-left: auto;
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
}

.shareBoAction {
    margin-left: 15px;
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
}

.titleChart {
    margin-bottom: 0;
    margin-top: 50px;
}

.selectFilterDate {
    width: 240px;
    margin-left: 20px;
    position: relative;

    &:before {
        content: "";
        z-index: 60;
        position: absolute;
        top: 12px;
        left: 14px;
        width: 16px;
        height: 16px;
        background: url("../../../../assets/icons/calendar.svg");
    }

    :global {
        .select__value-container {
            padding-left: 40px;
        }
    }
}

.projectSelect {
    width: 300px;
}

.downloadIcon {
    margin-right: 10px;
}

.shareIcon {
    margin-right: 10px;
    width: 15px;
}

.filters {
    margin-top: 20px;
    margin-bottom: 25px;
    display: flex;
}

.filtersItem {
    min-width: 240px;
    margin-right: 20px;
}

.hiddenPrintBlock {
    position: absolute;
    top: -99999px;
}
