.root {
    width: 100%;
}

.footer {
    box-shadow: none;
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.03);
    position: fixed; /* instead of absolute */
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 54px;
    transition: bottom 0.2s ease-in-out;
}

.actions {
    display: flex;
    justify-content: space-between;
    margin: 11px 29px 13px 310px;
}

.actionFeedback {
    font-size: 11px;
    font-weight: 500;
    color: #5f6683;
    border-radius: 5px;
    box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.03);
    padding: 9px 24px;
    min-width: 140px;
    border: solid 1px #f0f1f6;
}

.actionOffer {
    color: #fffcfc;
}

.hidden {
    display: none;
}
