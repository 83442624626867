.accordion {
    margin-top: 10px;
}

.accordionItem {
    margin-bottom: 10px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px #f4f4f6;
    border-radius: 5px;
}

.accordionItemActive {
    composes: accordionItem;

    .accordionIcon {
        transition: transform 500ms ease;
        transform: rotate(-90deg);
    }

    .folderIcon {
        display: none;
    }

    .folderOpenIcon {
        display: unset;
    }
}

.accordionIcon {
    transform: rotate(90deg);
    transition: transform 500ms ease;
    margin-right: 15px;
}

.folderIcon {
    margin-right: 15px;
}

.folderOpenIcon {
    margin-right: 15px;
    display: none;
}

.accordionButton {
    display: flex;
    align-items: center;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;

    &:hover {
        cursor: pointer;
    }
}

.name {
    font-size: 12px;
    font-weight: 600;
    color: #4c4e5a;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    max-width: 550px;
}

.fileName {
    &:hover {
        background-color: #fff;
        cursor: pointer;
    }
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 130px;
}

.time,
.date {
    font-size: 11px;
    font-weight: 500;
    color: #a3a8bb;
    margin-left: auto;
}

.accordionPanel {
    padding: 25px 10px 0 0;
}

.file {
    display: flex;
    align-content: center;
    padding-left: 60px;
    margin-bottom: 20px;
}

.fileIcon {
    margin-right: 20px;
    fill: #a3a8bb;
}

.fileTime {
    margin-left: auto;
}

.dateFile {
    margin-left: 50px;
}

.actionDownload {
    background-color: transparent;
    border: none;
    margin-right: 30px;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

.actionRemove {
    color: #ff6578;
}
