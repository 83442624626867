.root {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 3px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    margin-bottom: 20px;
}

.title {
    line-height: 1;
    font-size: 12px;
    font-weight: bold;
    color: #4c4e5a;
}

.actionReadDocument {
    height: 30px;
    font-size: 11px;
    font-weight: 500;
    color: #5f6683;
    border-radius: 5px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px #f0f1f6;
    background-color: #ffffff;
    padding-left: 24px;
    padding-right: 24px;

    &:hover {
        cursor: pointer;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.09);
    }
}

.actionDownload {
    background-color: transparent;
    border: none;
    text-decoration: none;
    color: #5f6683;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}
