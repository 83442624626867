.root {
    width: 100%;
}

.addButton {
    padding-left: 33px;
    font-weight: 600;
}

.colsName {
    display: flex;
    font-size: 9.5px;
    color: #a3a8bb;
    padding-top: 22px;
}

.colDocumentName {
    width: 25%;
    padding-left: 43px;
}

.assignedProject {
    width: 20%;
    padding-left: 25px;
}

.disclosingParty {
    width: 20%;
    padding-left: 14px;
}

.recievingParty {
    width: 35%;
    padding-left: 17px;
}

.accordion {
    margin-top: 16px;
}

.accordionItem {
    border-radius: 3px;
    background-color: #fff;
    margin-bottom: 12px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
}

.accordionIcon {
    transform: rotate(90deg);
    transition: transform 500ms ease;
    margin-right: 15px;
}

.accordionItemActive {
    composes: accordionItem;
    .accordionIcon {
        transition: transform 500ms ease;
        transform: rotate(-90deg);
    }
}

.actionFile {
    padding-right: 15px;
    padding-left: 15px;
    background-color: transparent;
    color: #5f6683;
    border: solid 1px #f0f1f6;
    display: flex;
    font-size: 11px;
    font-weight: 600;
    border-radius: 5px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    height: 32px;
    align-items: center;
    justify-content: center;
    margin-left: 15px;

    &:hover {
        cursor: pointer;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.09);
    }
}

.actionFileIcon {
    margin-right: 7px;
}

.actionFileUpload {
    margin-left: auto;
}

.accordionButton {
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 12px;
    font-weight: 600;
    color: #4c4e5a;
    line-height: 1;
    border-bottom: 1px solid #f0f1f6;

    &:hover {
        background-color: #fff;
        cursor: pointer;
    }
}

.accordionButtonHeight {
    height: 70px;
}

.accordionItemPanel {
    padding: 0 10px 10px;
}

.ndaUser {
    margin-right: 2px;
}

.userAvatar {
    margin-right: 12px;
    font-weight: 900;
}

.col {
    display: flex;
    align-items: center;
}

.colProject {
    width: 20%;
    border-right: 1px solid #f0f1f6;
    font-weight: 700;
    color: #4c4e5a;
    padding-left: 25px;
    height: 100%;
}

.colDiscParty {
    width: 20%;
    padding-left: 20px;
    padding-right: 20px;
    padding-left: 14px;
    font-weight: 500;
    border-right: 1px solid #f0f1f6;
    height: 100%;
}

.colRecParty {
    width: 35%;
    padding-left: 17px;
    justify-content: flex-start;
}

.actionMenu {
    margin-left: auto;
}

.headerTitle {
    justify-content: space-between;
}

.buttons {
    display: flex;
}

.templatesBtn {
    color: #5f6683;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 10px;
    min-width: 122px;
}

.leftBtn {
    margin-right: 12px;
}

.signedBtn {
    color: #fffcfc;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 10px;
    min-width: 122px;
}

.date,
.time {
    margin-left: auto;
    width: 80px;
    padding-right: 10px;
    color: #a3a8bb;
    font-size: 11px;
    font-weight: 500;
}

.date {
    margin-left: 0;
    width: 140px;
    padding-right: 10px;
}

.action {
    padding-right: 10px;
}
