.root {
    padding: 9px 0 12px 0;
    font-size: 12px;
    font-weight: 600;
    color: #4c4e5a;
    line-height: 1;
    border-bottom: 1px solid #f0f1f6;
    border-radius: 3px;
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
}

.adminPhoto {
    margin-right: 10px;
}

.adminInfo {
    display: flex;
    align-items: center;
    padding: 18px 0 0 24px;
    font-size: 12px;
    font-weight: 500;
    color: #4c4e5a;
}

.actionMenu {
    margin-left: auto;
}

.accountUser {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 900;
    border-radius: 3px;
}

.form {
    margin-left: auto;
    padding: 0 23px 8px 23px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid#f0f1f6;
}

.title {
    padding: 18px 0 18px 0;
}

.field {
    max-width: 233px;
    margin-left: auto;
    padding: 0 23px;
    display: flex;
    justify-content: space-between;
}

.option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 9px 12px;
}

.col {
    display: flex;
    align-items: center;
    padding: 25px;
    margin-left: auto;
    color: #a3a8bb;
}

.userType {
    margin-top: auto;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 15px;
    height: 15px;
}

.name {
    padding-top: 5px;
    margin-bottom: 3px;
    overflow: hidden;
    font-size: 14px;
    font-weight: bold;
}

.userInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 5px;
}

.adminSelect {
    width: 239px;
    font-size: 11px;
    font-weight: 600;
    :global {
        .select__placeholder {
            color: #5f6683;
        }
    }
}
