.container {
    padding: 24px 93px 31px 78px;
}

.modal {
    width: 1007px;
}

.header {
    padding-left: 78px;
    padding-top: 73px;
    flex-wrap: wrap;
}

.headerTitle {
    font-size: 24px;
    font-weight: 600;
    color: #4c4e5a;
}

.wrapperContent {
    width: 100% !important;
    height: 220px !important;
}

.content {
    padding-right: 10px;
}

.continueBtn {
    min-width: 140px;
    margin-left: 18px;
    font-size: 14px;
    font-weight: 500;
    padding: 11px 43px;
    color: #fffcfc;
}

.leaveBtn {
    color: #5f6683;
}

.row {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    margin-bottom: 38px;
}

.codemaster {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 51px;
    display: flex;
    font-size: 11px;
    font-weight: 500;
    color: #587b77;
}

.infoRow {
    display: flex;
    border-bottom: 1px solid #f0f1f6;
}

.disclosing {
    border-right: 1px solid #f0f1f6;
    padding: 19px 103px 65px 78px;
    max-width: 50%;
}

.receiving {
    padding: 19px 103px 65px 78px;
}

.partyTitle {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.3px;
    color: #a5aabd;
}

.partyText {
    font-size: 11px;
    letter-spacing: 0.28px;
    color: #a3a8bb;
}
