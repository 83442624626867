.modal {
  width: 750px;
}

.close {
  right: 67px;
  top: 36px;
}

.header {
  padding-left: 70px;
}

.content {
  padding: 25px 67px 0 69px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
}

.field {
  max-width: 290px;
}

.checkboxText {
  font-size: 12px;
  margin-left: 13px;
}

.footer {
  padding: 19px 0 86px 0;
}

.actionButton {
  font-weight: 500;
  padding: 13px 15px;
}
