.modal {
    width: 750px;
}

.header {
    padding-top: 10px;
    padding-left: 70px;
    min-height: 112px;
}

.titleHeader {
    font-size: 22px;
    font-weight: 600;
    color: #4e4c5a;
}

.close {
    right: 67px;
    top: 55px;
}

.content {
    margin: 9px 66px 0 69px;
}

.column {
    flex: 1 1;
    flex-wrap: wrap;
}

.accountItem {
    display: flex;
    justify-content: space-between;
    padding: 25px;
    border-radius: 3px;
    box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
    height: 60px;
    max-width: 50%;
    margin-bottom: 77px;
    font-size: 14px;
    font-weight: bold;
    color: #4c4e5a;
    align-items: center;
}

.footer {
    padding: 0px 66px 59px 73px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

button.submitBtn {
    margin-left: 16px;
    min-width: 140px;
    padding: 12px 41px;
    font-weight: 500;
    color: #fffcfc;
    border-radius: 3px;
    box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px #f0f1f6;
}


.filter {
    width: 260px;
    margin-bottom: 16px;
}

.wrapperContent {
    width: 100% !important;
    height: 321px !important;
}

.colName {
    display: flex;
    align-items: center;
    width: 50%;
    padding: 10px;
    align-items: stretch;
    border-right: 1px solid #f0f1f6;
    font-size: 14px;
    font-weight: bold;
    color: #4c4e5a;

    &:hover {
        opacity: 0.9;
        cursor: pointer;
    }
}

.user {
    display: flex;
    justify-content: space-between;
    margin: 23px 0 17px 0;
    background-color: #ffffff;
}

.userInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 5px;
}

.accountInfo {
    align-items: center;
    display: flex;
    padding-right: 20px;
}

.userType {
    margin-top: auto;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 15px;
    height: 15px;
}

.boldText {
    font-size: 14px;
    font-weight: bold;
    color: #4c4e5a;
}

.title {
    font-size: 14px;
    letter-spacing: 0.35px;
    color: #4c4e5a;
    padding-bottom: 16px;
}
