.root {
    width: 100%;
}

.list {
    background-color: #f4f4f6;
}

.empty {
    display: flex;
    padding-top: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.emptyDescription {
    color: #a3a8bb;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
}

.root {
    width: 100%;
}

.container {
    padding-top: 13px;
    padding-right: 109px;
}

.list {
    background-color: #f4f4f6;
}

.empty {
    display: flex;
    padding-top: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.emptyDescription {
    color: #a3a8bb;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
}

button.leaveBtn {
    min-width: 120px;
    padding: 9px 39px;
}

button.continueBtn {
    padding: 9px 39px;
    color: #5f6683;
    border-radius: 5px;
    box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px #f0f1f6;
    min-width: 120px;
    margin-left: 18px;
}

.footer {
    display: flex;
    padding: 16px 0 39px 28px;
}

.offersItem {
    background-color: #ffffff;
    margin-bottom: 30px;
    border-radius: 3px;
    box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px #f5f6fb;
}

.text {
    font-size: 11px;
    color: #a3a8bb;
    padding: 20px 37px 45px 28px;
}

.header {
    border-bottom: 1px solid#f0f1f6;
    padding: 19px 28px;
    justify-content: space-between;
    display: flex;
    font-size: 14px;
    font-weight: 600;
}

.colName {
    font-weight: 600;
    color: #4c4e5a;
}

.info {
    font-size: 11px;
    font-weight: 500;
    color: #a3a8bb;
    display: flex;
}

.colNumber {
    padding-right: 21px;
    margin-right: 19px;
    border-right: 1px solid #f0f1f6;
}

.col {
    margin: 3px 50px 0 0;
}

.visibilityIcon {
    background-color: transparent;
    border: transparent;
    min-height: 20px;

    &:hover {
        opacity: 0.9;
        cursor: pointer;
    }
}
