.root {
    border-radius: 3px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
    padding-bottom: 40px;
    margin-bottom: 40px;
}

.header {
    height: 70px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #f0f1f6;
}

.title {
    color: #4c4e5a;
    font-size: 16px;
    margin: 0;
    font-weight: 600;
    display: flex;
    align-content: center;
}

.titleIcon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.messages {
    padding-top: 30px;
}

.message {
    margin-bottom: 12px;
    padding-left: 25px;
    padding-right: 25px;

    &:nth-child(2n) {
        padding-bottom: 30px;
        border-bottom: 1px solid #f0f1f6;
    }
}

.messagesWithoutAnswer {
    .time,
    .date {
        font-weight: bold;
        color: #4c4e5a;
    }

    .messageContent {
        background-color: #9dce6b;
        color: #fff;
    }
}

.messageInfo {
    max-width: 820px;
    margin-bottom: 2px;
    padding-right: 25px;
    display: flex;
    align-items: center;
}

.messageRight {
    margin-left: auto;

    .messageInfo {
        margin-left: auto;
    }

    .messageContent {
        margin-left: auto;
        border-top-right-radius: 0;
        border-top-left-radius: 20px;
        color: #ffffff;
        background-color: rgba(#9296a7, 0.5);
    }
}

.messageContent {
    color: #a3a8bb;
    max-width: 820px;
    margin: 0;
    background-color: #f3f4f9;
    min-height: 65px;
    padding: 12px 15px 15px;
    border-radius: 0 20px 20px 20px;
}

.form {
    padding-left: 15px;
    padding-right: 15px;
}

.nameField {
    font-size: 12px;
    color: #4c4e5a;
    margin-bottom: 10px;
}

.filed {
    display: flex;
    flex-direction: column;
}

.time {
    margin-left: 15px;
    font-size: 11px;
    font-weight: 500;
    color: #a3a8bb;
}

.date {
    margin-left: auto;
    font-size: 11px;
    font-weight: 500;
    color: #a3a8bb;
}

.textarea {
    border-radius: 3px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
    font-size: 14px;
    padding: 15px;
    color: #4c4e5a;
    height: 90px;
    resize: none;
    border: none;
}

.submit {
    margin-top: 15px;
    padding-top: 9px;
    padding-bottom: 9px;
    font-size: 14px;
    font-weight: 500;
    min-width: 120px;
    margin-left: auto;
}
