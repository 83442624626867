.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.actionImage {
    padding: 0;
    color: #9dce6c;
    font-size: 14px;
    font-weight: 500;
}

.pinnedIcon {
    margin: 2px 12px 2px 0;
}
