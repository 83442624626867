.modal {
    max-width: 480px;
    width: 100%;
}

.header {
    padding-left: 70px;
    padding-right: 70px;
}

.container {
    padding: 50px 70px 45px;
}

.field {
    margin-bottom: 200px;
}

.actions {
    display: flex;
    justify-content: space-between;
}

.action {
    min-width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;

    font-weight: 500;
}

.actionCancel {
    color: #5f6683;
}
