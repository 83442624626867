.root {
    //background-color: #15342c;
    background-color: #1b4c1f;
    padding-top: 40px;
    padding-bottom: 40px;
}

.topRow {
    display: flex;
    justify-content: space-between;
}

.socials {
    display: flex;
}

.social {
    font-size: 16px;
    color: #f7f8fa;
    text-decoration: none;
    font-weight: 600;

    &:hover {
        opacity: 0.8;
        text-decoration: underline;
    }
}

.bottomRow {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rights {
    font-size: 12px;
    color: #f7f8fa;
}

.link {
    text-decoration: none;
    color: #f7f8fa;
    font-size: 12px;
    font-weight: 500;

    &:hover {
        opacity: 0.8;
        text-decoration: underline;
    }
}
