.accordionItem {
    border-radius: 3px;
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
}

.accordionIcon {
    transition: transform 500ms ease;
}

.accordionItemActive {
    composes: accordionItem;

    .accordionIcon {
        transition: transform 500ms ease;
        transform: rotate(-90deg);
    }
}

.accordionButton {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    font-size: 14px;
    font-weight: 600;
    color: #4c4e5a;
    line-height: 1;
    border-bottom: 1px solid #f0f1f6;

    &:hover {
        background-color: #fff;
        cursor: pointer;
    }
}

.priceRow {
    font-size: 12px;
    color: #4c4e5a;
    font-weight: 600;
}

.offerComment {
    font-size: 11px;
    color: #a3a8bb;
    margin-top: 12px;
    margin-bottom: 20px;
}

.fileRow {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.actionDownload {
    display: flex;
    align-items: center;
    text-decoration: none;
    background-color: transparent;
    border: none;
    margin-right: 30px;
    color: #4c4e5a;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

.fileName {
    margin-right: 10px;
    font-weight: bold;
    font-size: 12px;
}

.offerTooltip {
    margin-left: 15px;
    min-width: 210px;
}

.tooltipFullId {
    padding: 10px;
    color: #4c4e5a;
}

.user {
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.investorFullName {
    margin-left: 10px;
    font-size: 12px;
}

.flexSpace {
    margin-left: auto;
}

.offerStatus {
    text-transform: capitalize;
    min-width: 85px;
    font-size: 10px;
    font-weight: 500;
    padding: 8px 19px;
    border-radius: 3px;
}

.offerStatusAccepted {
    color: #9dce6c;
    background-color: #eaf5df;
}

.offerStatusPending {
    background-color: #c3e9fb;
    color: #6d99d0;
}

.offerStatusDeclined {
    color: #a3a8bb;
    background-color: #f3f4f9;
}

.offerTime {
    color: #a3a8bb;
    font-size: 11px;
    margin-left: 50px;
}

.offerDate {
    margin-left: 40px;
    color: #a3a8bb;
    font-size: 11px;
    margin-right: 10px;
}

button.actionChangeStatus {
    min-width: 100px;
    padding: 6px 20px;
}