.select {
    outline: none;
    border: none;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);

    &:before {
        z-index: 2 !important;
    }

    :global {
        .select__control {
            border-radius: 3px;
            outline: none;
            border: none;
            min-height: 40px;
            font-size: 14px;
            font-weight: 300;
            background-color: #ffffff;
        }

        .select__indicator {
            &:hover {
                cursor: pointer;
            }
        }

        .select__single-value {
            color: #404040;
            font-weight: 500;
        }

        .select__indicator-separator {
            display: none;
        }

        .select__control--is-focused {
            border-radius: 3px;
            outline: none;
            border: none;
            box-shadow: 0 0 2px #000000;
        }

        .select__value-container {
            padding-left: 20px;
            box-shadow: none;
        }

        .select__placeholder {
            color: #8f94ab;
        }

        .select__option {
            &:hover {
                cursor: pointer;
            }
        }

        .select__option--is-selected {
            background-color: rgb(157, 206, 108);

            &:hover {
                cursor: unset;
            }
        }
    }
}

.inputError {
    box-shadow: 0 0 2px #ff6578;

    &:focus {
        box-shadow: 0 0 2px #ff6578;
    }
}
