.root {
    width: 100%;
}

.list {
    margin-top: 10px;
}

.headerActions {
    width: 100%;
    display: flex;
    align-items: center;
    flex: 1;
    margin-left: 15px;
}

.actionArchivedProject {
    margin-left: auto;
    color: #4c4e5a;
    font-size: 11px;
    font-weight: 600;
    padding: 14px 18px 12px 15px;
    margin-right: 15px;
    position: relative;
}

.actionArchivedProjectActive {
    box-shadow: none;
    color: #9dce6c;
}

.archivedIcon {
    position: absolute;
    top: 10px;
    left: 12px;
    fill: #a3a8bb;
}

.archivedIconActive {
    fill: #9dce6c;
}

.filterActions {
    margin-left: auto;
    display: flex;
}
