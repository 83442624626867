.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 140px;
    background-color: #fff;
    border: none;
    border-radius: 3px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    width: 20%;
    margin: 15px 2.5%;

    transition: transform 200ms ease;

    &:hover {
        cursor: pointer;
        transition: transform 200ms ease;
        transform: scale(1.1)
    }
}

.activeCard {
    background-color: rgb(157, 206, 108);

    .value {
        color: #fff;
    }

    .description {
        color: #fff;
    }

    &:hover {
        cursor: unset;
        transform: none;
    }
}

.value {
    color: #4b736e;
    font-size: 48px;
    font-weight: 300;
}

.description {
    font-size: 15px;
    font-weight: 500;
    color: #5e6a66;
}
