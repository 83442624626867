.modal {
    width: 750px;
}

.header {
    padding-left: 70px;
    padding-top: 15px;
    min-height: 112px;
}

.titleHeader {
    font-size: 22px;
    font-weight: 600;
    color: #4e4c5a;
}

.close {
    right: 67px;
    top: 51px;
}

.content {
    display: flex;
    justify-content: space-between;
    margin: 25px 35px 0 69px;
}

.column {
    flex: 1 1;
    flex-wrap: wrap;
}

.field {
    max-width: 290px;
    margin-bottom: 14px;
}

.textarea {
    padding: 14px 19px;
    height: 198px;
}

.checkboxText {
    font-size: 12px;
    margin-left: 13px;
}

.footer {
    padding: 40px 66px 86px 0;
}

.submit {
    margin-left: auto;
    font-weight: 500;
    color: #fffcfc;
}
