.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    border-radius: 3px;
}

.value {
    font-size: 48px;
    font-weight: 300;
}

.title {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    color: #5e6a66;
}
