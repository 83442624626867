.modal {
  width: 750px;
  height: 639px;
  overflow: hidden;
}

.commentModal {
  width: 562px;
  height: 448px;
}

.header {
  padding-left: 42px;
  padding-top: 15px;
  min-height: 112px;
  max-width: 620px;
}

.headerTitle {
  color: #4c4e5a;
  font-weight: 600;
  font-size: 18px;
  max-width: 400px;
}

.close {
  right: 52px;
  top: 52px;
}

.content {
  margin: 25px auto 33px;
  width: 88%;
  height: 385px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.03);
    background-color: #9dce6c;
  }
}

.column {
  flex: 1 1;
  flex-wrap: wrap;
}

.field {
  max-width: 290px;
  margin-bottom: 14px;
}

.textarea {
  padding: 14px 19px;
  height: 198px;
}

.checkboxText {
  font-size: 12px;
  margin-left: 13px;
}

.footer {
  //padding-right: 62px;
}

.submit {
  margin-left: auto;
  margin-right: 45px;
  font-weight: 500;
  color: #fffcfc;
}

.container {
  font-size: 12px;
  color: #4c4e5a;
  width: 84%;
  margin: 22px auto;
  padding: 0;
}

.notificationMessage {
  min-height: 375px;
  padding: 82px 100px 55px 97px;
}

.messageContainer {
  display: flex;
  align-items: center;
  margin-bottom: 175px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.35px;
  color: #404040;
}

.acceptIcon {
  margin-right: 31px;
}

.codemaster {
  width: 100%;
  margin-top: 4px;
  display: flex;
  font-size: 11px;
  font-weight: 500;
  color: #587b77;
}

.textarea {
  padding: 14px 18px;
  width: 472px;
  height: 206px;
  margin: 7px 0 37px 0;
}

.title {
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 20px;
  color: #a5aabd;
}

.sendBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fffcfc;
  min-width: 128px;
  padding: 10px 46px 10px 46px;
  margin-left: auto;
}

a {
  text-decoration: none;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}

.teammatesHolder {
  height: 9px;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5e6a66;
}