.root {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    background-color: #fff;
}

.notificationsList {
    padding-left: 35px;
    padding-right: 35px;
}

.action {
    max-width: 490px;
}
