.accordion {
    margin-top: 20px;
}

.accordionItem {
    border-radius: 3px;
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
}

.accordionIcon {
    transform: rotate(90deg);
    transition: transform 500ms ease;
    margin-right: 15px;
}

.accordionItemActive {
    composes: accordionItem;
    .accordionIcon {
        transition: transform 500ms ease;
        transform: rotate(-90deg);
    }
    .ndaUsers {
        display: none;
    }
}

.actionFile {
    padding-right: 15px;
    padding-left: 15px;
    background-color: transparent;
    color: #5f6683;
    border: solid 1px #f0f1f6;
    display: flex;
    font-size: 11px;
    font-weight: 600;
    border-radius: 5px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    height: 32px;
    align-items: center;
    justify-content: center;
    margin-left: 15px;

    &:hover {
        cursor: pointer;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.09);
    }
}

.actionFileIcon {
    margin-right: 7px;
}

.actionFileUpload {
    margin-left: auto;
}

.accordionButton {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    color: #4c4e5a;
    line-height: 1;
    border-bottom: 1px solid #f0f1f6;

    &:hover {
        background-color: #fff;
        cursor: pointer;
    }
}

.accordionButtonHeight {
    height: 70px;
}

.accordionButtonNDA {
    font-size: 12px;
    font-weight: bold;
}

.accordionItemPanel {
    padding: 0 10px 10px;
}

.ndaUsers {
    height: 50px;
    display: flex;
    align-items: center;
    margin-left: auto;
    border-left: 1px solid #f0f1f6;
    padding-left: 15px;
}

.ndaUser {
    margin-right: 5px;
}
