.root {
    border-radius: 3px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
    padding-bottom: 40px;
    margin-bottom: 40px;
    margin-top: 20px;
}

.header {
    font-size: 16px;
    color: #4c4e5a;
    height: 70px;
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 18px 20px;
    border-bottom: 1px solid #f0f1f6;
}

.title {
    color: #4c4e5a;
    font-size: 16px;
    margin: 0;
    font-weight: 600;
}

.root .offers {
    background-color: #ffffff;
}
.root .container {
    padding-left: 15px;
    padding-right: 15px;
}
