.icon {
    fill: #a3a8bb;
    transform: rotate(90deg);
}

.actionToggle {
    padding-left: 3px;
    padding-right: 3px;

    &:hover {
        cursor: pointer;
        animation: drag infinite 400ms alternate;
    }
}

@keyframes drag {
    0% {
        transform: rotate(-40deg);
    }

    25% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(40deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.content {
    background-color: #fff !important;
    margin-top: 5px !important;
    opacity: 1 !important;
    display: flex !important;
    flex-direction: column !important;
    min-width: 125px !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    text-align: left !important;

    &:before {
        content: none !important;
    }

    &:after {
        content: none !important;
    }
}
