.root {
    padding-top: 55px;
    //padding-bottom: 93px;
    padding-bottom: 45px;
    background-color: #fff;
}

.title {
    color: #4c4e5a;
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 35px;
}

.paragraphs {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #404040;
}

.paragraphsCol {
    max-width: 537px;
}

.paragraph {
    margin: 0 0 25px;
    line-height: 1.5;
}

.link {
    text-decoration: none;
    color: #4c4e5a;
    font-size: 16px;
    font-weight: 400;

    &:hover {
        opacity: 0.8;
        text-decoration: underline;
    }
}

