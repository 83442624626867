.root {
    color: inherit;
    background-color: transparent;
    padding: 0;
    border: none;
    min-height: 11px;
    display: flex;
    align-items: center;

    &:focus {
        outline: 1px solid black;
    }

    &:hover {
        cursor: pointer;
    }
}

.active {
    color: #4c4e5a;
    font-weight: 500;
}

.iconUp {
    margin-left: 8px;
    transform: rotate(-90deg);
}

.iconDown {
    margin-left: 3px;
    margin-right: 3px;
    transform: rotate(90deg);
}

.iconActive {
    fill: #9dce6b;
}
