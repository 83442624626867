.notificationsItem {
    display: flex;
    margin-bottom: 21px;
    font-size: 12px;
    justify-content: space-between;
}

.action {
    margin-left: 18px;
}

.boldText {
    font-weight: bold;
}

.link {
    color: #9dce6c;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
}
