.modal {
  max-width: 500px;
  width: 100%;
}

.close {
  right: 56px;
  top: 36px;
}

.header {
  padding-left: 58px;
  padding-right: 58px;
}

.headerTitle {
  color: #404040;
}

.container {
  padding: 40px 57px 56px;
}

.message {
  padding-bottom: 40px;
  font-size: 15px;
  color: #404040;
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.action {
  min-width: 140px;
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: 12px;
  font-weight: 500;
}
