.closeBtn {
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    margin: auto 0;
}

:global {
    .Toastify__toast-container {
        width: 765px;
    }
    .Toastify__toast--default {
        background: #fff;
        color: #aaa;
    }
    .Toastify__toast--info {
        background: #3498db !important;
    }
    .Toastify__toast--success {
        min-height: 48px;
        background: #9dce6b !important;
        color: white !important;
        font-size: 13px;
        font-weight: 500;
        padding-left: 25px;
        padding-right: 23px;
    }
    .Toastify__toast--warning {
        min-height: 48px;
        background: #f1c40f !important;
        color: white !important;
        font-size: 13px;
        font-weight: 500;
        padding-left: 25px;
        padding-right: 23px;
    }
    .Toastify__toast--error {
        min-height: 48px;
        background: #ff495f !important;
        color: white !important;
        font-size: 13px;
        font-weight: 500;
        padding-left: 25px;
        padding-right: 23px;
    }
}
