.root {
    color: #4c4e5a;
    font-size: 11px;
    font-weight: 600;
    background-color: transparent;
    border: none;
    text-align: left;
    padding: 10px 15px;

    &:hover {
        cursor: pointer;
        background-color: #E9E9E9;
    }

    &:disabled {
        background-color: transparent;
        color: #a3a8bb;
        cursor: unset;
    }
}
