.colNames {
    margin-top: 35px;
    display: flex;
    color: #a3a8bb;
    font-size: 10px;
}

.colName {
    padding-left: 15px;
}

.colNameName {
    width: 25%;
}

.colNameOwner {
    width: 15%;
}

.colNameState {
    width: 15%;
}

.colNameAccess {
    width: 25%;
}

.list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.empty {
    display: flex;
    margin: 0 auto;
    padding-top: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.emptyDescription {
    color: #a3a8bb;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
}
