.root {
    display: flex;
    list-style: none;
    margin: 40px auto 20px;
    padding: 0;
    justify-content: center;
    align-items: center;
}

.page {
    margin: 5px;
}

.pageLink {
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    background-color: #9dce6c;
    padding: 0 12px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    line-height: 1;
    font-size: 12px;
    color: #fffcfc;

    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
}

.nextLink,
.previousLink {
    background-color: #fffcfc;
    color: #9dce6c;
    height: 37px;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    line-height: 1;
    font-weight: bold;
    font-size: 15px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.06);

    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
}

.breakLink {
    height: 37px;
    display: flex;
    align-items: flex-end;
    padding-left: 10px;
    padding-right: 10px;

    &:hover {
        cursor: pointer;
        color: #9dce6c;
    }
}

.activeLink {
    opacity: 0.6;

    &:hover {
        opacity: 0.6;
        cursor: unset;
    }
}
