.container {
    padding: 18px 130px 31px 128px;
    border-bottom: 1px solid #f0f1f6;
}

.modal {
    width: 1007px;
}

.header {
    padding: 69px 0 45px 124px;
}

.titleHeader {
    font-size: 24px;
    font-weight: 600;
    color: #4c4e5a;
}

.title {
    text-transform: uppercase;
    font-weight: bold;
}

.wrapperContent {
    width: 100% !important;
    height: 220px !important;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px #f0f1f6;
    background-color: #fff;
}

.content {
    padding: 21px 44px 41px 32px;
    font-size: 14px;
    letter-spacing: 0.35px;
    color: #4c4e5a;
}

.saveBtn {
    min-width: 164px;
    margin-left: 18px;
    font-weight: 500;
    color: #fffcfc;
    padding: 16px 64px;
}

.row {
    margin-top: 40px;
    justify-content: flex-end;
    display: flex;
    margin-bottom: 38px;
}

.textarea {
    padding: 14px 18px;
    height: 122px;
}

.body {
    padding: 34px 44px;
    height: 321px;
}

.filename {
    display: flex;
    align-items: center;
    color: #4c4e5a;
}
