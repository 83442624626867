.root {
    display: flex;
    margin-bottom: 15px;
}

.rootRight {
    .wrapperContent {
        order: 2;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .avatar {
        order: 3;
        margin-left: 5px;
        margin-right: 0;
    }

    .userFullName {
        margin-left: auto;
    }

    .content {
        background-color: rgba(#9296a7, 0.5);
        color: #fff;
        border-radius: 20px 0 20px 20px;
    }

    .data {
        margin-top: 25px;
        margin-right: 5px;
        margin-left: 0;
        white-space: nowrap;
    }

    .file {
        background-color: darken(rgb(157, 206, 108), 10%);
    }
}

.avatar {
    margin-right: 5px;
}

.wrapperContent {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.content {
    margin: 0;
    padding: 11px 15px 21px;
    width: 165px;
    background-color: #f3f4f9;
    border-radius: 0 20px 20px 20px;
    font-size: 10px;
    color: #a3a8bb;
    word-break: break-all;
}

.userFullName {
    color: #4c4e5a;
    font-size: 11px;
    font-weight: 600;
    margin: 0;
}

.data {
    font-size: 10px;
    font-weight: 500;
    color: #a3a8bb;
    margin-top: 25px;
    margin-left: 5px;
}

.file {
    width: 165px;
    margin-top: 4px;
    display: flex;
    background-color: rgb(157, 206, 108);
    padding: 5px 7px;
    text-decoration: none;
    border: none;
    font-size: 11px;
    max-width: 100%;
    line-height: 1;
    color: #ffffff;
    border-radius: 3px;
    align-items: center;
    justify-content: space-between;
}

.fileName {
    overflow: hidden;
    width: 100px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fileIcon {
    fill: #fff;
    margin-right: 10px;
}

.fileIconDownload {
    fill: #fff;
    margin-left: 10px;
}
