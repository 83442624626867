.container {
    padding: 27px 62px 31px 78px;
}

.modal {
    width: 980px;
}

.header {
    padding-left: 78px;
}

.title {
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 20px;
    color: #a5aabd;
}

.wrapperContent {
    width: 100% !important;
    height: 220px !important;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px #f0f1f6;
    background-color: #fff;
}

.content {
    font-size: 11px;
    letter-spacing: 0.25px;
    padding: 20px;
    color: #a3a8bb;
    text-align: justify;
}

.leaveBtn {
    color: #5f6683;
    min-width: 165px;
    margin-left: auto;
}

.labelConfirmAgreement {
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

.continueBtn {
    min-width: 164px;
    margin-left: 18px;
}

.row {
    margin-top: 40px;
    display: flex;
    margin-bottom: 38px;
}

.checkbox {
    margin-top: 50px;
}
