.root {
  width: 100%;
}

.cards {
  display: flex;
  flex-wrap: wrap;
}

.card {
  min-width: 126px;
  min-height: 126px;
  margin-left: 15px;
}


.topRow {
  display: flex;
  margin-top: 25px;
  margin-bottom: 20px;
}

.bottomRow {
  display: flex;
}

.notificationsList {
  min-height: 380px;
}

.usersList {
  margin-right: 15px;
}
