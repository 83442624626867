.editor {
    margin-top: 6px;
    border: 1px solid#f0f1f6;
    background-color: white;

    .rdw-editor-main {
        padding: 0 20px 10px;
    }
}

.wrapper {
    padding-bottom: 15px;
}

.editorClass {
    height: 246px;
    padding: 1px 15px;
}

.label {
    font-size: 12px;
    margin-bottom: 5px;
    color: #4c4e5a;
    position: relative;
}

.required {
    padding-left: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #ff364e;
    position: absolute;
}
