.container {
    padding: 8px 100px 55px 97px;
    font-size: 12px;
    color: #4c4e5a;
}

.notificationMessage {
    min-height: 375px;
    padding: 82px 100px 55px 97px;
}

.messageContainer {
    display: flex;
    align-items: center;
    margin-bottom: 175px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.35px;
    color: #404040;
}

.acceptIcon {
    margin-right: 31px;
}

.modal {
    width: 1007px;
}

.row {
    display: flex;
    margin-bottom: 14px;
}

.field {
    max-width: 290px;
    margin-right: 34px;
}

.header {
    padding: 74px 0 17px 97px;
    flex-wrap: wrap;
}

.header h3 {
    flex-wrap: wrap;
    font-size: 22px;
    font-weight: 600;
    color: #4c4e5a;
}

.modal button {
    top: 65px;
    right: 103px;
}

.codemaster {
    width: 100%;
    margin-top: 4px;
    display: flex;
    font-size: 11px;
    font-weight: 500;
    color: #587b77;
}

.textarea {
    padding: 14px 18px;
    height: 149px;
}

.title {
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 20px;
    color: #a5aabd;
}

.sendBtn {
    min-width: 128px;
    padding: 10px 34px;
    font-size: 14px;
    font-weight: 500;
    color: #fffcfc;
    margin-left: auto;
}

.footer {
    justify-content: space-between;
    display: flex;
    margin-top: 23px;
}

.upload {
    color: #9dce6c;
}

.filename {
    display: flex;
    align-items: center;
    color: #4c4e5a;
    padding-left: 15px;
}

.message {
    display: flex;
    justify-content: center;
    font-size: 12px;
    color: #404040;
}
