.accountList {
  display: flex;
  justify-content: space-between;
  font-family: Montserrat;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4b736e;
  font-weight: 300;
  width: 99%;
  border-radius: 5px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #f0f1f6;
  background-color: #fff;
  height: 60px;
  padding: 21px 11px;
  margin: 10px 0;
}

.accountStatus,
.accountStatusActive,
.accountStatusRemind,
.accountStatusInactive {
  width: 81px;
  height: 32px;
  margin-top: -8px;
  padding: 7px;
  border-radius: 3px;
  font-size: 14px;
  text-align: center;
}

.accountStatus {
  background-color: #f3f4f9;
}

.accountStatusActive {
  background-color: #eaf5df;
  color: #9dce6c;
}

.accountStatusRemind {
  background-color: #c3e9fb;
  color: #6d99d0;
}

.accountStatusInactive {
  border-radius: 3px;
  box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;

}

.accountLastConnectInfo {
  font-size: 12px;
  width: 12%
}

.accountMainStatuses {
  width: 12%;
  font-size: 12px;
  color: #9dce6c;
}

.accountActivityInfo {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 12px;
  margin-top: -8px;
}

.accountActivityAction {
  font-weight: 500;
}

.iconWrapper,
.commentButton,
.accountName{
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  pointer-events: auto;

  &:hover {
    cursor: pointer;
  }
}

.iconWrapper {
  width: 20px;
  height: 20px;
  &:hover {
    transform: scale(1.3);
    transition: transform 200ms ease;
  }

}

.commentButton {
  width: 74%;
  height: 30px;
  margin-top: -6px;
  font-size: 12px;
  text-align: left;

  &:hover {
    color: #9dce6c;
    text-decoration: underline;
    transform: scale(1.05);
    transition: transform 200ms ease;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20%;
}

.accountName {
  font-weight: 500;
  font-size: 16px;

  &:hover {
    text-decoration: underline;
    transform: scale(1.05);
    transition: transform 200ms ease;
  }
}

.accountNameContainer {
  width: 26%;
  margin: 0;
  padding: 0;
}
