.root {
    padding-top: 10px;
    position: relative;
    margin-top: auto;

    &:before {
        content: "";
        left: -16px;
        position: absolute;
        top: 0;
        height: 1px;
        width: 295px;
        background-color: #f0f1f6;
    }
}

.nameField {
    font-weight: normal;
    display: inline-block;
    color: #4c4e5a;
    font-size: 12px;
    margin-bottom: 5px;
}

.textarea {
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    font-size: 14px;
    padding: 15px;
    color: #4c4e5a;
    height: 90px;
    resize: none;
    border: none;
}

.actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
}

.uploadFileAction {
    min-width: auto;
    box-shadow: none;
    background-color: transparent;
    color: #9dce6c;
    font-size: 11px;
    font-weight: 500;
}

.submitAction {
    min-width: 120px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.fileName {
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.actionCleanFile {
    background-color: transparent;
    margin: 0 10px 0 0;
    padding: 0;
    border: none;

    transition: transform 0.4s ease;

    &:hover {
        transition: transform 0.4s ease;
        transform: scale(1.2);
        cursor: pointer;
    }

    &:disabled {
        opacity: 0.5;

        &:hover {
            transform: unset;
            cursor: unset;
        }
    }
}
