.ndaItem {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding-left: 20px;
}

.avatar {
    margin-right: 10px;
}

.userName {
    font-size: 12px;
    font-weight: 500;
    color: #4c4e5a;
    width: 150px;
    padding-right: 10px;
}

.actions {
    display: flex;
    justify-content: flex-end;
}

.fileName {
    width: 210px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 600;
    color: #4c4e5a;
}

.date,
.time {
    margin-left: auto;
    width: 80px;
    padding-right: 10px;
    color: #a3a8bb;
    font-size: 11px;
    font-weight: 500;
}

.date {
    margin-left: 0;
    width: 140px;
    padding-right: 10px;
}

.actionDownload {
    background-color: transparent;
    border: none;
    margin-right: 30px;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}
