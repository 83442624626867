.root {
    width: 100%;
}

.container {
    padding-top: 13px;
    padding-right: 109px;
}

.list {
    background-color: #f4f4f6;
}

.empty {
    display: flex;
    padding-top: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.emptyDescription {
    color: #a3a8bb;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
}

.buttons {
    display: flex;
}

button.acceptBtn {
    color: #fffcfc;
    font-size: 12px;
    font-weight: 500;
    min-width: 120px;
    padding: 9px 39px;
    border-radius: 5px;
    box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.03);
}

button.declineBtn {
    color: #5f6683;
    font-size: 12px;
    font-weight: 500;
    border: solid 1px #f0f1f6;
    margin-left: 18px;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0 39px 28px;
}

.offersItem {
    background-color: #ffffff;
    margin-bottom: 30px;
    border-radius: 3px;
    box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px #f5f6fb;
}

.offerText {
    font-size: 11px;
    color: #a3a8bb;
    padding: 20px 37px 0 28px;
}

.header {
    border-bottom: 1px solid #f0f1f6;
    padding: 19px 27px;
    justify-content: space-between;
    display: flex;
}

.amount {
    font-size: 14px;
    font-weight: 600;
    color: #4c4e5a;
}

.date {
    font-size: 11px;
    font-weight: 500;
    color: #a3a8bb;
}

.leftCol {
    padding-right: 21px;
    margin-right: 19px;
    border-right: 1px solid #f0f1f6;
}

.rightCol {
    margin-right: 50px;
}

.wrapperContent {
    width: 100% !important;
    height: 100px !important;
}

.actionDownload {
    background-color: transparent;
    border: none;
    margin-right: 30px;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

.buttons {
    display: flex;
}

.file {
    display: flex;
    line-height: 1;
    align-items: center;
}

.fileName {
    margin-right: 10px;
    font-size: 12px;
    font-weight: 600;
    color: #4c4e5a;
}

.pendingBtn {
    color: #fffcfc;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 10px;
    min-width: 122px;
}

.activeBtn {
    color: #5f6683;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 10px;
    min-width: 122px;
}

.leftBtn {
    margin-right: 12px;
}

.headerTitle {
    justify-content: space-between;
    padding-right: 82px;
}

.accordionAdminList {
    border: none;
}