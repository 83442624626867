
.root {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.col {
    display: flex;
    align-items: center;
    padding: 13px 0 15px 0;
    font-size: 9.5px;
    font-weight: 500;
    justify-content: center;
    color: #4c4e5a;
}

.deletedFilter {
    margin-left: 50px;
    display: flex;
}

.colName {
    width: 25%;
    justify-content: flex-start;
    padding-left: 10px;
}

.colPhone {
    width: 15%;
    justify-content: center;
    color: #a3a8bb;
}

.colEmail {
    width: 20%;
    justify-content: center;
    color: #a3a8bb;
}

.btnIcon {
    background-color: transparent;
    border: transparent;
    min-height: 20px;
}

.colActions {
    padding: 22px 0 6px 44px;
}

.actionMenu {
    margin-left: auto;
}

.sort {
    display: flex;
}

.filter {
    display: flex;
    margin-right: 3px;
}

.buttons {
    display: flex;
}

.userTypeButton {
    color: #5f6683;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 10px;
    min-width: 122px;
}

.userTypeButtonActive {
    color: #fffcfc;
    font-weight: 500;
}

.leftBtn {
    margin-right: 12px;
}
