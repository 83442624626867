.root {
    width: 100%;
    min-height: 48px;
    border: none;
    text-align: left;
    border-top: 1px solid #f0f1f6;
    background-color: #fff;
    padding-right: 25px;
    padding-left: 24px;
    position: relative;
    font-size: 11px;
    font-weight: 500;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #4c4e5a;
    margin-top: auto;

    &:before {
        content: "";
        position: absolute;
        top: 18px;
        right: 25px;
        width: 7px;
        height: 10px;
        background: url(../../../assets/icons/mini-arrow.svg) center/contain;
    }

    &:hover {
        cursor: pointer;
        background-color: darken(#fff, 3);
    }
}
