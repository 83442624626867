.menu {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.menuItem {
  background-color: #587b77;
  color: #d2e1df;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 60px;
  text-decoration: none;

  &:hover {
    color: #8ebb6e;

    path {
      fill: #8ebb6e !important;
    }
  }
}

.menuItemActive {
  background-color: #4b736e;
  border-color: #4b736e;
  position: relative;

  &::after {
    content: " ";
    width: 10px;
    height: 10px;
    background: white;
    transform: rotate(45deg);
    position: absolute;
    right: -5px;
    top: 25px;
  }
}

.menuItemCount {
  margin-left: 10px;
  background-color: #9dce6b;
  font-size: 11px;
  font-weight: 500;
  border-radius: 50%;
  min-width: 24px;
  height: 24px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#fff;
  line-height: 1;
}
