.root {
    width: 100%;
}

.list {
    margin-top: 10px;
}

.headerActions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    margin-left: 15px;
}

.headerBtn {
    display: flex;
}
