.root {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #fff;
  border-bottom: 1px solid #f0f1f6;
  border-radius: 3px 3px 0 0;
}

.title {
  color: #4c4e5a;
  font-size: 16px;
  font-weight: 600;
}

.presentationWrapper {
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 0 0 3px 3px;
  padding-bottom: 10px;
}

.actionFile {
  padding-right: 15px;
  padding-left: 15px;
  background-color: transparent;
  color: #5f6683;
  border: solid 1px #f0f1f6;
  display: flex;
  font-size: 11px;
  font-weight: 600;
  border-radius: 5px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
  height: 32px;
  align-items: center;
  justify-content: center;
  margin-left: 15px;

  &:hover {
    cursor: pointer;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.09);
  }
}

.statisticInfo {
  display: flex;
  justify-content: space-between;
  list-style: none;
  font-family: Montserrat;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4b736e;
}

.statisticInfo {
  width: 96%;
  border-radius: 5px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #f0f1f6;
  background-color: #fff;
  height: 104px;
  padding: 14px 50px;
  margin: 18px 17px 36px;
  font-weight: 300;
}

.statisticInfo li span {
  font-size: 36px;
}

.statisticInfo li h6 {
  margin: 5px 0 0 0;
  font-size: 15px;
  font-weight: 500;
}

.mainInfo {
  align-self: baseline;
  margin: 0 23px;
  width: 97%;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.03);
    background-color: #9dce6c;
  }
}

.menuItems {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  padding-left: 9px;
  display: inline-block;
}

.noContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 330px;
  color: #a3a8bb;
}


