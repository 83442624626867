*,
::after,
::before {
    box-sizing: border-box;
}

body {
    font-family: Montserrat, Arial, sans-serif;
    padding: 0;
    margin: 0;
    background-color: #f4f4f6;
}

.app {
    min-height: 100vh;
}

:global {
    .sb-avatar--src img {
        object-fit: contain;
    }
}
