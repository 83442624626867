.container {
    padding: 0;
}

.modal {
    width: 1007px;
}

.header {
    color: #404040;
}

.field {
    max-width: 360px;
    margin-bottom: 16px;
}

.fieldTown {
    max-width: 310px;
    margin-bottom: 16px;
}

.content {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e9;
    padding: 20px 128px 38px 128px;
}

.content:first-child {
    padding-top: 41px;
}

.profile {
    display: flex;
    min-width: 389px;
    color: #fffcfc;
}

.emptyBlock {
    display: flex;
    min-width: 389px;
}

.zipCity {
    display: flex;
    max-width: 360px;
}

.zipField {
    width: 45%;
    margin-bottom: 16px;
    margin-right: 10px;
}

button.submitBtn {
    min-width: 164px;
    font-weight: 500;
}

button.btnChangePass {
    border-radius: 3px;
    box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px #f0f1f6;
    color: #5f6683;
    width: 120px;
    height: 10px;
    font-size: 11px;
    font-weight: 500;
    min-width: 120px;
    margin-left: 239px;
}

button.btnImage {
    border-radius: 5px;
    min-width: 144px;
    margin-bottom: 13px;
    height: 30px;
    font-size: 11px;
    font-weight: 500;
}

.data {
    display: flex;
    flex-direction: column;
    min-width: 360px;
}

.footer {
    display: flex;
    justify-content: flex-end;
    padding: 62px 128px 56px 125px;
}
