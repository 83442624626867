.root {
    padding-bottom: 15px;
    border-bottom: 2px solid #e7e7e9;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    margin-top: 25px;
}

.card {
    height: 180px;

    border-radius: 3px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);

    background: #fff url(../../../../assets/images/bg-card.png) no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cardPlusValue {
    color: #4c4e5a;
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 15px;
}

.cardValue {
    font-size: 48px;
    font-weight: 300;
    color: #4b736e;
    line-height: 1;
    margin-bottom: 15px;
}

.cardTitle {
    color: #4c4e5a;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    line-height: 1;
}

.select {
    min-width: 100px;
    font-size: 15px;
    min-height: 10px;

    :global {
        .select__control {
            border: none;
            min-height: 10px;
            background-color: transparent;

            &:hover {
                cursor: pointer;

                .select__indicator {
                    color: hsl(0,0%,60%);
                }
            }
        }

        .select__value-container {
            padding: 0;
        }

        .select__indicator {
            padding: 0;
        }

        .select__indicator-separator {
            display: none;
        }

        .select__menu-list {
            padding-top: 0;
            padding-bottom: 0;
        }

        .select__option {
            &:hover {
                cursor: pointer;
            }
        }

        .select__option--is-selected {
            background-color: #9dce6c;
        }
    }
}
