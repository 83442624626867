.root {
  background-color: #587b77;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.33px;
  max-width: 278px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.logo {
  max-width: 101px;
  margin: 39px 0 66px 61px;
}
