.modal {
    max-width: 950px;
    width: 100%;
}

.header {
    padding-left: 70px;
    padding-right: 70px;
}

.container {
    padding-top: 15px;
    padding-bottom: 15px;
}

.presentationFrame {
    width: 100%;
    height: 550px;
}
