.container {
    padding: 0;
}

.modal {
    width: 747px;
}

.field {
    max-width: 360px;
    margin-bottom: 16px;
}

.content {
    display: flex;
    border-bottom: 1px solid #e7e7e9;
}

.content:first-child {
    padding: 25px 35px 35px 29px;
    justify-content: space-between;
}

.contactInfo {
    border-right: 1px solid #f0f1f6;
    flex: 1 1;
    padding: 20px 0 29px 30px;
}

.ndaInfo {
    border-right: 1px solid #f0f1f6;
    flex: 1 1;
    padding: 20px 0 29px 14px;
}

.profileInfo {
    display: flex;
}

.container .btn {
    color: #5f6683;
}

.container .submitBtn {
    min-width: 164px;
}

.container .deleteBtn {
    min-width: 140px;
    font-size: 11px;
    color: #5f6683;
    border: solid 1px #f0f1f6;
}

button.btnImage {
    border-radius: 5px;
    min-width: 120px;
    margin-bottom: 13px;
    height: 30px;
    font-size: 12px;
    color: #fffcfc;
    font-weight: 500;
}

.name {
    font-size: 24px;
    font-weight: 600;
    padding-left: 16px;
    margin: 0;
    color: #4c4e5a;
}

.position {
    font-size: 13px;
    font-weight: 400;
    margin: 3px 0 8px 0;
    color: #4c4e5a;
}

.company {
    font-size: 11px;
    font-weight: 600;
    margin: 0;
    color: #587b77;
}

.profileBtns {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.footer {
    display: flex;
    justify-content: space-between;
}

.footer .card {
    min-width: 33.33%;
    min-height: 131px;
    background-color: #f4f4f6;
}

.footer .cardTitle {
    font-size: 36px;
}

.projects {
    padding: 16px 32px 28px 32px;
    width: 100%;
}

.partisipated {
    padding: 0 32px 68px 30px;
    width: 100%;
    flex: 1 1;
    columns: 2 auto;
    justify-content: space-between;
}

.projectItem {
    border-radius: 3px;
    box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
    padding-left: 11px;
    height: 44px;
    font-size: 14px;
    font-weight: 600;
    color: #4c4e5a;
}

.partisipatedItem {
    margin-bottom: 13px;
    display: flex;
    padding: 10px;
    min-width: 335px;
    justify-content: space-between;
}

.col {
    display: inline-block;
}

.projectTitle {
    margin-top: 5px;
}

.projectStatus {
    margin: 10px 0 9px 19px;
    padding: 8px;
}

.role {
    height: 25px;
    margin-top: 15px;
}

.firstLastName {
    margin-right: 25px;
}

.infoTitle {
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.25px;
    color: #a5aabd;
    text-transform: uppercase;
}

.partisipatedTitle {
    margin: 16px 0 13px 31px;
}

.contactPhone {
    font-size: 18px;
    font-weight: 600;
    color: #4c4e5a;
    margin: 0 0 14px 0;
}

.contactEmail {
    font-size: 15px;
    font-weight: 600;
    color: #9dce6c;
    margin: 0;
}

.ndaUser {
    margin-right: 4px;
}

.wrapperContent {
    width: 100% !important;
    height: 220px !important;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px #f0f1f6;
    background-color: #fff;
}
