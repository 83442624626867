.pleaText {
    color: #404040;
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 5px;
}

.description {
    color: #a3a8bb;
    font-size: 13px;
    margin-bottom: 30px;
}

.field {
    margin-bottom: 25px;
}

.zipCity {
    display: flex;
}

.zipField {
    width: 45%;
    margin-right: 10px;
    margin-bottom: 25px;
}

.actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 46px;
}

.formPasswordActions {
    justify-content: flex-end;
    margin-top: 105px;
}

.action {
    padding-top: 11px;
    padding-bottom: 11px;
}

.actionSkipStep {
    color: #8f94ab;
    border: solid 1px #f0f1f6;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.04);
}
