.modal {
    width: 750px;
}

.close {
    right: 67px;
    top: 51px;
}

.header {
    padding-left: 70px;
    padding-top: 20px;
    min-height: 112px;
}

.headerTitle {
    color: #4c4e5a;
    font-size: 22px;
    font-weight: 600;
}

.content {
    padding: 20px 67px 17px 69px;
    border-bottom: 1px solid #e7e7e9;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
}

.field {
    max-width: 290px;
}

.checkboxText {
    font-size: 12px;
    margin-left: 13px;
}

.footer {
    padding: 19px 0 19px 0;
}

.actionButton {
    font-weight: 500;
    padding: 13px 15px;
    margin-left: auto;
    color:#FFFCFC;
}

.checkbox {
    font-size: 12px;
}
