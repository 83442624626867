.root {
    padding-top: 30px;
    background: url(../../../../assets/images/bg-teaser.jpg) no-repeat;

    background-size: 101% 200px;
    background-position-x: -1px;
}

.topRow {
    display: flex;
    justify-content: space-between;
}

.pageTitle {
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 12px;
}

.actionBack {
    font-size: 13px;
    color: #ffffff;
    text-decoration: none;
    position: relative;
    padding-left: 36px;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 16px;
        background: transparent url(../../../../assets/icons/arrow-simple.svg)
            no-repeat center;
        background-size: contain;
    }

    &:hover {
        text-decoration: underline;
        cursor: pointer;

        &:before {
            transform: rotate(360deg);
            transition: transform 0.5s ease;
        }
    }
}

.userFullName {
    color: #ffffff;
    font-size: 12px;
    margin-left: 7px;
    font-weight: bold;
}

.projectInfo {
    display: flex;
    align-items: flex-start;
    border-radius: 3px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    background-color: #f5f4f4;
    margin-top: 40px;
}

.projectAvatar {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-radius: 3px;
    object-fit: contain;
    min-width: 165px;
}

.projectContent {
    width: 100%;
}

.projectTitleBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    padding-left: 15px;
    padding-right: 30px;
    min-height: 55px;
}

.projectTitle {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #4c4e5a;
}

.statusTag {
    margin-left: 10px;
    margin-right: 8px;
}

.projectUserAvatar {
    margin-left: auto;
    color: #4c4e5a;
}

.actionEditProject {
    margin-left: 35px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: 90px;
    border-radius: 5px;
    font-size: 11px;
    font-weight: 500;
    color: #fffcfc;
}

.businessBlock {
    padding-left: 15px;
    padding-top: 15px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
}

.statisticCards {
    display: flex;
}

.statisticCard {
    margin-right: 10px;
}

.businessType {
    font-size: 10px;
    margin: 0 0 10px;
    font-weight: bold;
    color: #4b736e;
    text-transform: uppercase;
}

.businessDescription {
    max-width: 300px;
    font-size: 12px;
    color: #a3a8bb;
    margin: 0;
    padding-bottom: 10px;
}

.investorActionsWrapper {
    display: flex;
    flex-direction: column;
}

.actionMakeOffer {
    height: 30px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    width: 140px;
    margin-left: auto;
}

.userActions {
    margin-top: 10px;
    display: flex;
}

.userAction {
    margin-left: 15px;
    border-radius: 5px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px #f0f1f6;
    color: #5f6683;
    font-size: 11px;
    font-weight: 500;
    height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.actionsWrapper {
    display: flex;
}

.infoWrapper {
    display: flex;
    align-items: center;
}
