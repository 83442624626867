.modal {
  width: 750px;
}

.header {
  padding-left: 70px;
  min-height: 112px;
}

.titleHeader {
  font-size: 22px;
  font-weight: 600;
  color: #4e4c5a;
}

.close {
  right: 67px;
  top: 51px;
}

.content {
  margin: 9px 66px 0 69px;
}

.column {
  flex: 1 1;
  flex-wrap: wrap;
}

button.addBtn {
  padding: 14px 44px;
  min-width: 114px;
  font-size: 12px;
  font-weight: 500;
  color: #fffcfc;
}

button.removeBtn {
  padding: 14px 31px;
  color: #5f6683;
  border: solid 1px #f0f1f6;
}

.accountItem {
  margin-right: 15px;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-radius: 3px;
  box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  height: 60px;
  margin-bottom: 11px;
  font-size: 14px;
  font-weight: bold;
  color: #4c4e5a;
  align-items: center;
}

.checkboxText {
  font-size: 12px;
  margin-left: 13px;
}

.footer {
  padding: 40px 66px 54px 73px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
}

.submit {
  margin-left: auto;
  font-weight: 500;
  color: #fffcfc;
}

.filter {
  width: 260px;
  margin-bottom: 16px;
}

.wrapperContent {
  width: 100% !important;
  height: 321px !important;
}

.empty {
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.emptyDescription {
  color: #a3a8bb;
  font-size: 20px;
  font-weight: 300;
  margin: 0;
}
